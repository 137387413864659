import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { requestInteractionAcknowledgement } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { Base, Button, ButtonGroup, ListItemText, Menu, MenuItem, Separator, Typography, } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
import { useNavigation } from '../../context';
export const ManagerActions = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.managerActions' });
    const { interaction, refetchInteraction } = useInteraction();
    const { setView } = useNavigation();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isActionLoading, setIsActionLoading] = React.useState(false);
    async function handleCompleteReview() {
        try {
            if (!interaction)
                return null;
            setIsActionLoading(true);
            await requestInteractionAcknowledgement(interaction.id, 'MANUAL');
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('requestAcknowledgmentSuccess', 'Acknowledgment requested'),
            });
            trackerService.track('[Acknowledgement Status] acknowledgment requested', {
                interactionID: interaction.id,
                status: 'MANUAL',
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('requestAcknowledgmentError', 'Failed to request acknowledgment'),
            });
        }
        finally {
            setIsActionLoading(false);
            setMenuAnchorEl(null);
        }
    }
    if (!interaction)
        return null;
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        React.createElement(Separator, { horizontal: true }),
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "caption", color: "neutralGray" }, t('completeBy', 'Complete by')),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { color: "primary", size: "small", onClick: () => setView('manual-assessment') },
                    React.createElement(AddRoundedIcon, null),
                    t('newAssessment', 'New Assessment')),
                React.createElement(Button, { color: "primary", size: "small", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget) },
                    React.createElement(ArrowDropDownIcon, null))),
            React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
                React.createElement(MenuItem, { onClick: handleCompleteReview, disabled: isActionLoading },
                    React.createElement(ListItemText, { primary: t('completeReview', 'Complete Review & Request Acknowledgment'), secondary: t('agentNotified', 'Agent will be notified.') }))))));
};
