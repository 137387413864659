import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card, CardContent, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../../../activeView/context/ParametersContext';
import { useDataContext } from '../../../../activeView/context/dataContext';
import { getPerformanceStatusColor } from '../../../../utils';
import { PerformanceIndicator } from '../../../components/PerformanceIndicator';
export const ComplianceCard = ({ title, score }) => {
    const { t } = useTranslation('performanceApp');
    const { palette } = useTheme();
    const { threshold } = useDataContext();
    const { goal } = useParametersContext();
    const { status, color } = getPerformanceStatusColor(palette, score, threshold, goal.enabled ? goal.value : undefined);
    const getBorderStyles = () => {
        return status === 'strong'
            ? { border: `1px solid ${palette.background.divider}` }
            : { border: `1px solid ${color}` };
    };
    const getBackgroundStyles = () => {
        return status === 'strong' ? {} : { background: transparentize(0.95, color) };
    };
    const conditionalCardStyles = goal.enabled
        ? css(Object.assign(Object.assign({}, getBorderStyles()), getBackgroundStyles()))
        : undefined;
    return (React.createElement(Card, { className: conditionalCardStyles, "data-cy": `compliance-card-${status}` },
        React.createElement(CardContent, { sx: {
                pt: 2,
                px: 5,
            }, classes: {
                root: css({
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }),
            } },
            React.createElement(Base, { mb: 2, overflow: "hidden" },
                React.createElement(Typography, { variant: "subtitle2", fontWeight: "600", color: "default", textOverflow: "ellipsis", overflow: "hidden", "data-cy": "compliance-card-title" }, title)),
            React.createElement(Base, { mb: 7 },
                React.createElement(Typography, { color: "neutralGray" },
                    t('protocolCompliancy.scoreDescriptionPrefix', 'Found in'),
                    React.createElement(Typography, { color: "default" }, ` ${score}% `),
                    t('protocolCompliancy.scoreDescriptionSuffix', 'of all calls'))),
            React.createElement(PerformanceIndicator, { value: score, target: goal.enabled ? goal.value : undefined, threshold: threshold }))));
};
