import React from 'react';
import { FailedAiScoreIndicator } from './FailedAiScoreIndicator';
import { PendingAiScoreIndicator } from './PendingAiScoreIndicator';
import { ScoreChip } from './ScoreChip';
import { SkippedStatusIndicator } from './SkippedStatusIndicator';
export const Score = ({ templateID, reviews = [] }) => {
    const reviewsWithTemplate = reviews.filter((r) => r.template_id === templateID);
    const latestReview = reviewsWithTemplate.find((r) => r.latest);
    const groundTruthReview = latestReview
        ? latestReview
        : [...reviewsWithTemplate].sort((a, b) => b.created_at.localeCompare(a.created_at))[0];
    if (!reviews.length ||
        !reviewsWithTemplate.length ||
        !groundTruthReview ||
        groundTruthReview.not_applicable) {
        return React.createElement(ScoreChip, null);
    }
    switch (groundTruthReview.status) {
        case 'PENDING_TRANSCRIPT':
            return React.createElement(PendingAiScoreIndicator, null);
        case 'FAILED':
            return React.createElement(FailedAiScoreIndicator, null);
        case 'SKIPPED':
            return React.createElement(SkippedStatusIndicator, null);
        default:
            return (React.createElement(ScoreChip, { value: groundTruthReview.score_percentage, status: groundTruthReview.compliance_status }));
    }
};
