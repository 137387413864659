var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Typography } from 'lib/cortiUI';
export const ActionButton = React.forwardRef((_a, ref) => {
    var { icon, label, onClick } = _a, props = __rest(_a, ["icon", "label", "onClick"]);
    const theme = useTheme();
    return (React.createElement(Base, Object.assign({ ref: ref, display: "flex", gap: 2, alignItems: "center", onClick: onClick, className: css({ cursor: 'pointer' }) }, props, { color: theme.palette.grey[700] }),
        icon,
        React.createElement(Typography, { variant: "body2", color: "neutralGray" }, label)));
});
