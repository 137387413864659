import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { DataGrid, GridToolbar, useContextMenu, useGridApiRef, } from 'lib/cortiUI';
import { useSearchState } from '../context';
import { useDataGridColumns } from '../utils';
import { DataGridRow } from './DataGridRow';
import { OpenNewTab } from './OpenNewTab';
import { filterColumns, getColumnForNewFilter, getRows, usePaginatedInteractions, } from './utils';
export const Grid = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const theme = useTheme();
    const apiRef = useGridApiRef();
    const { openContextMenu } = useContextMenu();
    const { items, totalItems, isLoading, pagination, sort, filters } = usePaginatedInteractions();
    const { visitedCalls, visibilitySettings: { density, visibleColumns, onDensityChange, onVisibleColumnsChange }, } = useSearchState();
    const columns = useDataGridColumns();
    const columnsVisibilityModel = columns.reduce((acc, column) => (Object.assign(Object.assign({}, acc), { [column.field]: visibleColumns.includes(column.field) })), {});
    const rows = getRows(items);
    function handleRowClick(caseID) {
        if (!caseID)
            return;
        visitedCalls.addVisitedCall(caseID);
        history.push(`${history.location.pathname}/${caseID}`);
    }
    function handleContextMenu(event, id) {
        if (!id)
            return;
        openContextMenu(event, React.createElement(OpenNewTab, { onClick: () => {
                visitedCalls.addVisitedCall(id);
                window.open(`${match.path}/${id}`, '_blank');
            } }));
    }
    function handleColumnVisibilityModelChange(model) {
        if (Object.keys(model).length) {
            onVisibleColumnsChange(Object.keys(model).filter((key) => model[key]));
        }
        else {
            const allColumns = columns.map((column) => column.field);
            onVisibleColumnsChange(allColumns);
        }
    }
    return (React.createElement(DataGrid, Object.assign({ apiRef: apiRef }, pagination, filters, sort, { density: density, onDensityChange: onDensityChange, columnVisibilityModel: columnsVisibilityModel, onColumnVisibilityModelChange: handleColumnVisibilityModelChange, rows: rows, rowCount: totalItems, columns: columns, disableColumnMenu: true, onRowClick: (params) => handleRowClick(params.row.interactionID), loading: isLoading, classes: {
            virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
        }, slots: {
            row: (props) => (React.createElement(DataGridRow, Object.assign({}, props, { onContextMenu: (event) => handleContextMenu(event, props.row.interactionID), isMarked: visitedCalls.isCallVisited(props.row.interactionID) }))),
            toolbar: GridToolbar,
        }, slotProps: {
            loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
            },
            toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
            pagination: { disabled: isLoading },
            filterPanel: {
                logicOperators: [],
                filterFormProps: {
                    filterColumns,
                },
                getColumnForNewFilter,
            },
        }, sx: {
            pt: 2,
            border: 'hidden',
            '& .MuiDataGrid-row': {
                cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
            },
        } })));
};
