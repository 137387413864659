import { ApolloProvider } from '@apollo/client';
import { LicenseInfo as MUILicenseInfo } from '@mui/x-license';
import { configure as configureMobx } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { isElectron } from '@corti/electron-utils';
import { I18nextProvider, intl } from '@corti/i18n';
import { RendererIpcLoggerTransport } from '@corti/ipc-renderer';
import { CoreApiError } from '@corti/lib/coreApiService';
import { logger } from '@corti/logger';
import { Theme as MissionControlTheme } from '@corti/mission-control-ui';
import { init as initCallSubscriberConfig } from 'browser/services/callSubscriberConfig.service';
import { apolloClient, init as initServices, services, setSwWrapperService, } from 'browser/services/init';
import { init as initBrowserApi } from 'core/api/browser/browserApi';
import { appDB, init as initAppDB } from 'core/appDB';
import { authStore, initAuthStore } from 'core/auth';
import { initApi as initAuthApi } from 'core/auth/auth.publicapi.impl';
import { AuthStoreProvider } from 'core/auth/browser';
import { config } from 'core/configuration/browser';
import { initConfig } from 'core/configuration/browser/initConfig';
import { showCoreApiErrorNotification } from 'core/coreApiErrorNotificationHandler';
import { env } from 'core/environment';
import { initEnv } from 'core/environment/browser/init';
import { loadModules } from 'core/moduleLoader/browser';
import { initModuleRegistry } from 'core/moduleRegistry/browser';
import { init as initProtocolGraphDB } from 'core/protocolGraphDB';
import { IPCAdapter } from 'core/publicapi/browser/ipc';
import { exposePublicApiOnWindow } from 'core/publicapi/browser/window';
import { PublicApiFramework } from 'core/publicapi/shared';
import { initRepositories, repositories } from 'core/repositories';
import { CortiUIContextProvider } from 'lib/cortiUI';
import { BreadcrumbContextProvider } from 'lib/router';
import { initBackendProxyApi } from './backendproxy.publicapi.impl';
import './index.css';
import { initIntl } from './intl';
import { history, initNavigationApi } from './navigation';
import { AppRoot } from './root';
import { RootStore } from './stores/RootStore';
import { initNotificationsApi } from './stores/notifications.publicapi.impl';
import { initRemoteRequestPublicApi } from './stores/remoterequest.publicapi.impl';
import { init as initStoreLocator } from './stores/storeLocator';
import { StoreContext } from './stores/utils';
import { createSwWrapper } from './sw';
async function main() {
    window.addEventListener('unhandledrejection', (event) => {
        // Code below is responsible for displaying core api service error notifications when rejected api requests are unhandled.
        // If you require local error handling while still wanting error notifications to be displayed, do one of the following:
        // - call the function below directly within the local error handler.
        // - implement your custom error notifications within the local error handler.
        // - re-throw the error within the local error handler.
        if (CoreApiError.isCoreApiError(event.reason)) {
            showCoreApiErrorNotification(event.reason);
        }
    });
    initEnv();
    const publicApiFramework = new PublicApiFramework(...[isElectron() ? new IPCAdapter() : undefined]);
    exposePublicApiOnWindow(publicApiFramework);
    await initConfig(publicApiFramework);
    await initCallSubscriberConfig();
    initNavigationApi(publicApiFramework);
    logger.setLevel(config.getConfig().debug ? 'DEBUG' : 'INFO');
    if (isElectron()) {
        logger.addTransport(new RendererIpcLoggerTransport());
    }
    initIntl({
        isDebugMode: env.isDebugMode(),
    });
    try {
        await initAppDB();
    }
    catch (err) {
        logger.error('Error when initialising app database: ', String(err));
    }
    await initServices();
    initBackendProxyApi(publicApiFramework, services.apiService);
    initRepositories(appDB);
    initAuthStore({
        publicApi: publicApiFramework,
        appDB,
    });
    initAuthApi(publicApiFramework, authStore);
    try {
        await initProtocolGraphDB();
    }
    catch (err) {
        logger.error('Error when initializing protocol graph database: ', String(err));
    }
    configureMobx({
        enforceActions: 'always',
    });
    const mobxStore = new RootStore();
    initNotificationsApi(publicApiFramework, mobxStore.notificationStore);
    initRemoteRequestPublicApi(publicApiFramework, mobxStore.remoteRequestStore);
    initStoreLocator(mobxStore);
    // For debugging
    window.__authStore__ = authStore;
    window.__rootStore__ = mobxStore;
    window.__repositories__ = repositories;
    initBrowserApi(mobxStore);
    initModuleRegistry(mobxStore, publicApiFramework);
    loadModules();
    if (process.env.MUI_LICENCE_KEY) {
        MUILicenseInfo.setLicenseKey(process.env.MUI_LICENCE_KEY);
    }
    let swWrapper;
    try {
        const r = await navigator.serviceWorker.register('/service-worker.js');
        swWrapper = createSwWrapper(r);
        setSwWrapperService(swWrapper);
    }
    catch (err) {
        console.error('Error when registering service worker', err);
    }
    ReactDOM.render(React.createElement(Observer, null, () => {
        var _a;
        return (React.createElement(I18nextProvider, { i18n: intl },
            React.createElement(Router, { history: history },
                React.createElement(MissionControlTheme, { appearance: (_a = authStore.themeID) !== null && _a !== void 0 ? _a : 'light' },
                    React.createElement(CortiUIContextProvider, { theme: authStore.theme },
                        React.createElement(BreadcrumbContextProvider, null,
                            React.createElement(ApolloProvider, { client: apolloClient },
                                React.createElement(StoreContext.Provider, { value: mobxStore },
                                    React.createElement(DndProvider, { backend: HTML5Backend },
                                        React.createElement(AuthStoreProvider, { value: authStore },
                                            React.createElement(AppRoot, { swWrapper: swWrapper })))))))))));
    }), document.getElementById('root'));
}
window.addEventListener('load', () => {
    void main();
});
