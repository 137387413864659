import AddCircleIcon from '@mui/icons-material/AddCircle';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, ChipV2, Dialog, State, Text, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { AttributeList, AttributeRendererString, CustomAttribute, GenericPanelBundle, } from 'lib/graphEditor/ui';
import { NodeSmartTarget } from '../canvas/views';
export const BranchInfoPanel = observer((_props) => {
    const theme = useTheme();
    const { editor } = useGraphEditorCtx();
    const branch = editor.state.activeBranch;
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(GenericPanelBundle, { header: t('branchInfoPanel.title', 'Branch Info') },
        React.createElement(AttributeList, null,
            React.createElement(AttributeRendererString, { "data-cy": "branch-name-input", name: t('branchInfoPanel.branchNameAttr', 'Branch Name'), value: branch.name, onValueChange: (value) => {
                    branch.setName(value);
                } })),
        React.createElement(AttributeList, null,
            React.createElement(CustomAttribute, { name: t('graphInfoPanel.startNodeAttr', 'Start Node') }, branch.startNode ? (React.createElement(NodeSmartTarget, { nodeID: branch.startNode.id })) : (React.createElement(Typography, { color: "error" }, t('graphInfoPanel.startNode.noNode', 'Node not set'))))),
        React.createElement(Base, null,
            React.createElement(Text, { noSelect: true, fontWeight: "bold", my: 3, color: theme.palette.grey[700] }, t('branchInfoPanel.keywordsSection.title', 'Keywords')),
            React.createElement(KeywordsSection, { branch: branch })),
        React.createElement(Button, { variant: "text", color: "error", disabled: editor.model.branches.length === 1, onClick: () => {
                if (confirm(t('branchInfoPanel.deleteBranchConfirmationMsg', 'Branch with {{nodeCount}} nodes will be deleted. Confirm?', { nodeCount: branch.nodes.size }))) {
                    editor.dispatch({ type: 'canvas.deleteBranch', data: { branchID: branch.id } });
                }
            } }, t('branchInfoPanel.deleteBranchBtn', 'Delete branch'))));
});
const KeywordsSection = observer(function KeywordsSection(props) {
    const VISIBILE_KEYWORDS = 5;
    const { branch } = props;
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const visibleKeywords = branch.keywords.slice(0, VISIBILE_KEYWORDS);
    const remainingKeywords = branch.keywords.slice(VISIBILE_KEYWORDS);
    return (React.createElement(Base, { display: 'flex', flexDirection: "column", gap: 3, alignItems: "start" },
        React.createElement(Base, null,
            React.createElement(Base, { display: 'flex', gap: 3, flexWrap: 'wrap' },
                visibleKeywords.map((keyword, idx) => {
                    return (React.createElement(KeywordChip, { key: idx, keyword: keyword, onDelete: () => {
                            editor.dispatch({
                                type: 'canvas.deleteBranchKeyword',
                                data: { branchID: branch.id, idx: idx },
                            });
                        } }));
                }),
                remainingKeywords.length === 0 ? null : (React.createElement(ChipV2, { label: t('branchInfoPanel.keywordsSection.hiddenMoreKeywords', '{{count}} more', {
                        count: remainingKeywords.length,
                    }), clickable: true, onClick: () => {
                        setOpen(true);
                    } })))),
        React.createElement(Button, { variant: "text", color: "primary", onClick: () => {
                setOpen(true);
            } }, t('branchInfoPanel.keywordsSection.addKeywordsButton', 'Add Keywords')),
        React.createElement(Dialog, { title: "Keywords", open: open, disableEscapeKeyDown: true, onClose: () => setOpen(false) },
            React.createElement(BranchKeywordsEditorView, { branch: branch }))));
});
const BranchKeywordsEditorView = observer(function BranchKeywordsEditorView(props) {
    const { branch } = props;
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { display: 'flex', flexWrap: "wrap", gap: 4 },
            branch.keywords.map((kwd, idx) => (React.createElement(KeywordChip, { key: idx, keyword: kwd, onDelete: () => {
                    editor.dispatch({
                        type: 'canvas.deleteBranchKeyword',
                        data: { branchID: branch.id, idx: idx },
                    });
                } }))),
            React.createElement(State, { initialState: { type: 'initial', inputValue: '' } }, ([input, setInput]) => {
                const i = (React.createElement("input", { className: css({
                        all: 'unset',
                        '&::placeholder': { color: 'white' },
                    }), autoFocus: true, placeholder: t('branchKeywordsEditorView.addNewKeywordInputPlaceholder', 'Press Enter to save'), onChange: (e) => setInput((state) => (Object.assign(Object.assign({}, state), { inputValue: e.target.value }))), onBlur: () => setInput({ type: 'initial', inputValue: '' }), onKeyDown: (e) => {
                        if (e.key === 'Escape') {
                            setInput({ type: 'initial', inputValue: '' });
                        }
                    }, onKeyUp: (e) => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                            if (input.inputValue.trim() === '') {
                                return;
                            }
                            setInput({ type: 'initial', inputValue: '' });
                            editor.dispatch({
                                type: 'canvas.addBranchKeyword',
                                data: {
                                    branchID: branch.id,
                                    keyword: input.inputValue,
                                },
                            });
                        }
                    } }));
                return (React.createElement(ChipV2, { icon: React.createElement(AddCircleIcon, null), label: input.type === 'initial'
                        ? t('branchKeywordsEditorView.addNewKeywordBtn', 'Click to add')
                        : i, color: 'primary', sx: {
                        width: '180px',
                        justifyContent: 'start',
                    }, clickable: true, onClick: () => {
                        setInput({ type: 'editing', inputValue: '' });
                    } }));
            }))));
});
function KeywordChip(props) {
    const { keyword, onDelete } = props;
    return React.createElement(ChipV2, { label: keyword, title: keyword, sx: { maxWidth: 160 }, onDelete: onDelete });
}
