import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { ScreenRecorderService } from '@corti/lib/screenRecorderService';
import { rbacService, trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { config } from 'core/configuration/browser';
import { Base, Button, ChipV2, Dialog, Typography } from 'lib/cortiUI';
import { useInteraction } from '../context';
import { ActionButton } from './components';
export const ScreenRecordings = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata.screenRecordings' });
    const { interaction, coreCall } = useInteraction();
    const { authToken } = useAuth();
    const [recordings, setRecordings] = React.useState([]);
    const [activeRecording, setActiveRecording] = React.useState();
    const screenRecorderApiHostRef = React.useRef(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    React.useEffect(() => {
        getRecordings().catch(() => coreStore.notifications.showNotification({
            type: 'error',
            message: t('fetchRecordingsError', 'Failed to fetch recordings.'),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coreCall]);
    if (!coreCall || !rbacService.appScreenRecorder() || recordings.length === 0)
        return null;
    async function getRecordings() {
        var _a;
        if (!coreCall || !authToken)
            return;
        const screenRecorderAPI = new ScreenRecorderService({
            host: config.getConfig().apiHost,
            authToken: authToken,
        });
        const res = await screenRecorderAPI.getRecordings({ case_id: coreCall.case.id });
        const sortedRecordings = res.sort((a, b) => a.monitorNo - b.monitorNo);
        screenRecorderApiHostRef.current = (_a = screenRecorderAPI.host) !== null && _a !== void 0 ? _a : null;
        setActiveRecording(sortedRecordings[0]);
        setRecordings(sortedRecordings);
    }
    function openRecordings() {
        setIsModalOpen(true);
        trackerService.track('[Case view] opened screen recordings', {
            interactionID: interaction === null || interaction === void 0 ? void 0 : interaction.id,
            caseID: coreCall === null || coreCall === void 0 ? void 0 : coreCall.case.id,
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionButton, { onClick: openRecordings, label: t('viewScreenRecording', 'View Screen Recording'), icon: React.createElement(SmartDisplayOutlinedIcon, { color: "action" }) }),
        React.createElement(Dialog, { open: isModalOpen, onClose: () => setIsModalOpen(false), title: t('screenRecordingsLabel', 'Screen Recordings'), size: "auto" },
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 5, m: 3 },
                React.createElement(Base, { flex: 1, gap: 6, display: "flex", flexDirection: "column" }, recordings.map((recording) => (React.createElement(Button, { variant: "text", color: recording.id === (activeRecording === null || activeRecording === void 0 ? void 0 : activeRecording.id) ? 'primary' : undefined, key: recording.id, onClick: () => setActiveRecording(recording), fullWidth: true, sx: { textAlign: 'start' }, noWrap: true }, `${t('recordingMonitorNumber', 'Monitor number')}: ${recording.monitorNo}`)))),
                React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5, flex: 2 },
                    React.createElement(Base, null,
                        React.createElement(Typography, { variant: "subtitle1", color: "default" }, t('selectedRecording', 'Selected recording ID:')),
                        React.createElement(ChipV2, { variant: "outlined", label: (activeRecording === null || activeRecording === void 0 ? void 0 : activeRecording.id)
                                ? activeRecording.id
                                : t('noSelectedRecording', 'Not selected') })),
                    activeRecording && screenRecorderApiHostRef.current && (React.createElement("video", { controls: true, width: 450, src: `${screenRecorderApiHostRef.current}/screen-recording-service/api/v1/screen-recordings/${activeRecording.id}/file?token=${authToken}` })))))));
};
