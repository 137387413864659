import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { formatDateTime, getTimeZone } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Base, Button, CircularProgress, Loading, PageHeader, PageHeaderActionsSection, Typography, } from 'lib/cortiUI';
import { useTranscriptSearchViewContext } from '../TranscriptSearchView/context';
import { EditableTitle } from '../components';
import { visitedCallsCache } from '../utils';
import { useTranscriptQueryViewContext } from './context';
export const Header = observer(function Header() {
    const theme = useTheme();
    const { router, utils } = useTranscriptSearchViewContext();
    const { viewModel } = useTranscriptQueryViewContext();
    const { t } = useTranslation();
    async function handleSaveQuery() {
        const { query } = viewModel.state;
        if (!query)
            return;
        const input = {
            name: query.name,
            timeZone: getTimeZone(),
            dateRange: query.parameters.tempDateRange,
            termGroups: query.parameters.filteredTempTermGroups,
            userIDs: query.parameters.tempUserIDs,
            teamIDs: query.parameters.tempTeamIDs,
            protocolNames: query.parameters.tempProtocolNames,
        };
        const getTrackData = (queryID) => ({
            transcriptQuery: Object.assign(Object.assign({ id: queryID }, input), { termGroups: input.termGroups.map((it) => it.terms) }),
        });
        const cacheUtils = visitedCallsCache();
        if (query.isDraft) {
            query.parameters.clearPersistedTempParameters();
            const queryID = await viewModel.createQuery(input);
            const draftCache = cacheUtils.getQueryCache('draft');
            cacheUtils.cacheVisitedCall(queryID, draftCache);
            cacheUtils.clearQueryCache('draft');
            router.toRoute(router.getRoute(queryID));
            trackerService.track('[Transcript search] transcript query created', getTrackData(queryID));
        }
        else {
            query.parameters.persistTempParameters();
            await viewModel.updateQuery(query.id, input);
            refetchData();
            trackerService.track('[Transcript search] transcript query updated', getTrackData(query.id));
        }
    }
    function refetchData() {
        const { query } = viewModel.state;
        if (!query)
            return;
        query.parameters.mergeParameters();
        const dateRange = utils.toAbsoluteDateRange(query.parameters.dateRange);
        if (!dateRange)
            return;
        const combinedUsers = utils.combineUserIDsAndTeamMemberIDs(query.parameters.tempUserIDs, query.parameters.tempTeamIDs);
        // promises don't reject
        void Promise.all([
            viewModel.loadTimeSeries({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
            viewModel.loadCalls({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
        ]);
    }
    if (viewModel.state.isLoadingQuery) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", "data-cy": "transcript-query-view-header-loading" }));
    }
    if (!viewModel.state.query) {
        return null;
    }
    return (React.createElement(PageHeader, { mb: 0 },
        React.createElement(Base, { minHeight: 85, overflow: "hidden", display: "flex", flexDirection: "column", justifyContent: "space-between", "data-cy": "transcript-query-view-header" },
            React.createElement(EditableTitle, { title: viewModel.state.query.name, onChange: viewModel.state.query.changeName }),
            React.createElement(Base, { display: "flex", flexDirection: ['column', 'column', 'row'], alignItems: ['flex-start', 'flex-start', 'center'], gap: 5 },
                viewModel.state.query.updatedAt && (React.createElement(Typography, { variant: "caption", color: "secondaryText" }, `${t('transcriptSearchApp:lastUpdated', 'Last updated')} ${formatDateTime(viewModel.state.query.updatedAt)}`)),
                viewModel.state.query.isUnsaved && (React.createElement(Base, { className: css({
                        backgroundColor: theme.palette.warning.main,
                        borderRadius: 2,
                        padding: `${theme.space[1]}px ${theme.space[3]}px`,
                    }) },
                    React.createElement(Typography, { variant: "caption", color: "inverted", noWrap: true }, t('transcriptSearchApp:unsavedChanges', 'Unsaved changes')))))),
        React.createElement(PageHeaderActionsSection, null,
            React.createElement(Button, { variant: "text", disabled: viewModel.state.isSavingQuery || viewModel.state.query.parameters.hasNoTerms, onClick: handleSaveQuery, "data-cy": "transcript-query-view-header-save-btn" },
                viewModel.state.isSavingQuery ? (React.createElement(CircularProgress, { color: "inherit", size: 20, mr: 4 })) : (React.createElement(SaveRoundedIcon, { fontSize: "small", className: css({ marginRight: 5 }) })),
                viewModel.state.isSavingQuery ? t('loading', 'Loading') : t('save', 'Save')))));
});
