import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { addMinutes, subMinutes } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { rbacService, trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { useInteraction } from '../context';
import { ActionButton } from './components';
const TIME_WINDOW_MINUTES = 10;
export const FindSimilarInteractions = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata' });
    const { interaction } = useInteraction();
    const history = useHistory();
    if (!interaction ||
        !authStore.isFeatureEnabled('mission-control-find-related-calls') ||
        !authStore.isFeatureEnabled('review') ||
        !rbacService.appReview())
        return null;
    function openExploreWithSimilarCases() {
        if (!interaction)
            return null;
        const timeWindowStart = subMinutes(new Date(interaction.call_started_at), TIME_WINDOW_MINUTES).toISOString();
        const timeWindowEnd = addMinutes(new Date(interaction.call_started_at), TIME_WINDOW_MINUTES).toISOString();
        trackerService.track('[Case view] opened related interactions', {
            interactionID: interaction === null || interaction === void 0 ? void 0 : interaction.id,
        });
        history.push(`/explore?startedAt=${timeWindowStart},${timeWindowEnd}&caseCreatedBy=${interaction.interaction_owner}`);
    }
    return (React.createElement(ActionButton, { label: t('findSimilarInteractions', 'Find Similar Interactions'), onClick: openExploreWithSimilarCases, icon: React.createElement(ManageSearchOutlinedIcon, { color: "action" }) }));
};
