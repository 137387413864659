import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { convertUtcToZonedTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { logger } from '@corti/logger';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Autocomplete, Base, Box, Button, Loading, MenuItem, Page, PageHeader, TextField, Typography, } from 'lib/cortiUI';
import { getProtocolNameOptionsQuery, } from './graphql';
import { api } from './services';
export const TheFinderView = () => {
    var _a;
    const { t } = useTranslation('theFinder');
    const [title, setTitle] = React.useState('');
    const [questionID, setQuestionID] = React.useState(0);
    const [scriptedPhrases, setScriptedPhrases] = React.useState([]);
    const [notScriptedPhrases, setNotScriptedPhrases] = React.useState([]);
    const [selectedProtocols, setSelectedProtocols] = React.useState([]);
    const [beforeTime, setBeforeTime] = React.useState(3);
    const [afterTime, setAfterTime] = React.useState(6);
    const [finderData, setFinderData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const previousFormValues = React.useRef();
    const isFormChanged = isEqual(previousFormValues.current, {
        questionID,
        title,
        scriptedPhrases,
        notScriptedPhrases,
        selectedProtocols,
        beforeTime,
        afterTime,
    });
    const { organization } = useAuth();
    const theFinderDataLogger = logger.getLogger('TheFinderData');
    const { data: getProtocolNameOptions } = useQuery(getProtocolNameOptionsQuery, {
        variables: {
            organizationID: organization.id,
        },
    });
    const protocolNameOptions = (_a = getProtocolNameOptions === null || getProtocolNameOptions === void 0 ? void 0 : getProtocolNameOptions.performanceMetricsFilter.protocolNames) !== null && _a !== void 0 ? _a : [];
    const isFormValid = questionID && (scriptedPhrases.length > 0 || notScriptedPhrases.length > 0);
    const handleLoadDataFromApi = async () => {
        var _a, _b, _c;
        if (!isFormValid) {
            return;
        }
        setIsLoading(true);
        try {
            const input = {
                questionID,
                title,
                scriptedPhrases,
                notScriptedPhrases,
                beforeTime,
                afterTime,
                selectedProtocols,
            };
            previousFormValues.current = input;
            const response = (_b = (await ((_a = api.transcripts) === null || _a === void 0 ? void 0 : _a.getTheFinderData(input)))) !== null && _b !== void 0 ? _b : [];
            setFinderData(response);
            if (response.length === 0) {
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: t('noDataLoaded', 'No Data Found'),
                });
            }
            else {
                coreStore.notifications.showNotification({
                    type: 'success',
                    message: t('dataLoadedSuccess', 'Data Found Successfully'),
                });
            }
        }
        catch (e) {
            if (e instanceof Error) {
                theFinderDataLogger.error(`Loading data from the finder failed with: `, e === null || e === void 0 ? void 0 : e.message, (_c = e === null || e === void 0 ? void 0 : e.stack) !== null && _c !== void 0 ? _c : '');
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    const manipulateCSVData = (data) => {
        var _a;
        const csvRows = [];
        const headers = Object.keys(data[0]).map((header) => {
            if (header === 'startedAt') {
                return 'URL';
            }
            return header;
        });
        csvRows.push(headers.join(';') + '\r\n');
        for (let row of data) {
            const startedAt = new Date(`${row.startedAt}${((_a = row.startedAt) === null || _a === void 0 ? void 0 : _a.charAt(row.startedAt.length - 1)) !== 'z' ? 'z' : ''}`);
            const startSearchDate = convertUtcToZonedTime(startedAt);
            const endSearchDate = new Date(startSearchDate.getTime() + 1000 * 60);
            const updatedRow = Object.assign(Object.assign({}, row), { startedAt: `${window.location.origin}/explore?startedAt=${startSearchDate.toISOString()},${endSearchDate.toISOString()}` });
            const values = Object.values(updatedRow).join(';') + '\r\n';
            csvRows.push(values);
        }
        return csvRows;
    };
    const handleDownloadCSVData = () => {
        var _a;
        if (!(finderData === null || finderData === void 0 ? void 0 : finderData.length)) {
            return;
        }
        const blob = new Blob(manipulateCSVData(finderData), { type: 'text/csv' });
        const filename = ((_a = finderData[0]) === null || _a === void 0 ? void 0 : _a.title) ? `${finderData[0].title}.csv` : `download.csv`;
        saveFileToDisk(blob, filename);
    };
    return (React.createElement(Page, { gap: 8 },
        React.createElement(Base, { display: "flex", justifyContent: "flex-start" },
            React.createElement(PageHeader, { mb: 0 },
                React.createElement(Typography, { variant: "h4", color: "default" }, t('appName', 'The Finder')))),
        React.createElement(Base, { display: "flex", justifyContent: "center" },
            React.createElement(Box, { py: 7, px: 8, flexDirection: "column", gap: 6, width: "50%" },
                React.createElement(TextField, { fullWidth: true, required: true, autoFocus: true, label: t('questionId', 'Question ID (Number)'), type: "number", value: questionID === 0 ? '' : questionID, onKeyPress: (e) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }, onChange: (e) => {
                        setQuestionID(e.target.value === '' ? 0 : parseInt(e.target.value, 10));
                    }, inputProps: { 'data-cy': 'question-id-inpt' } }),
                React.createElement(TextField, { fullWidth: true, "data-cy": "title-inpt", label: t('title', 'Title'), value: title, onChange: (e) => {
                        setTitle(e.target.value);
                    } }),
                React.createElement(Autocomplete, { multiple: true, size: "small", fullWidth: true, "data-cy": "protocol-name-sel", options: protocolNameOptions, getOptionLabel: (option) => option, disableCloseOnSelect: true, value: selectedProtocols, onChange: (_, value) => {
                        setSelectedProtocols(value);
                    }, renderInput: (props) => (React.createElement(TextField, Object.assign({}, props, { label: t('protocolName', 'Protocol Name') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option }), option)) }),
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "caption", color: "neutralGray" }, notScriptedPhrases.length === 0 && scriptedPhrases.length === 0
                        ? t('semiRequired', "'As scripted' OR 'Not as scripted' field must be filled in")
                        : undefined),
                    React.createElement(Autocomplete, { size: "small", fullWidth: true, "data-cy": "as-scrptd-inpt", value: scriptedPhrases || [], multiple: true, freeSolo: true, clearOnBlur: true, clearOnEscape: true, options: [], getOptionLabel: (option) => {
                            return option;
                        }, onChange: (_event, nextValues) => {
                            setScriptedPhrases(nextValues);
                        }, sx: { mb: '16px' }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: notScriptedPhrases.length > 0
                                ? t('scriptedPhrases.moreThanZero', '"As Scripted" phrases')
                                : t('scriptedPhrases.zero', '"As Scripted" phrases *'), fullWidth: true }))) }),
                    React.createElement(Autocomplete, { size: "small", fullWidth: true, value: notScriptedPhrases || [], multiple: true, "data-cy": "not-scrptd-inpt", freeSolo: true, clearOnBlur: true, clearOnEscape: true, options: [], getOptionLabel: (option) => {
                            return option;
                        }, onChange: (_event, nextValues) => {
                            setNotScriptedPhrases(nextValues);
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: scriptedPhrases.length > 0
                                ? t('notScriptedPhrases.moreThanZero', '"Not As Scripted" phrases')
                                : t('notScriptedPhrases.zero', '"Not As Scripted" phrases *'), fullWidth: true }))) })),
                React.createElement(Base, { display: "flex", gap: 4, width: "100%" },
                    React.createElement(TextField, { label: t('before', 'Seconds Before'), "data-cy": "sec-before-inpt", type: "number", value: beforeTime === undefined ? '' : beforeTime.toString(), onChange: (e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '') {
                                setBeforeTime(undefined);
                            }
                            else {
                                const newValue = parseInt(inputValue, 10);
                                setBeforeTime(newValue >= 0 ? newValue : beforeTime);
                            }
                        }, sx: { flex: 1 } }),
                    React.createElement(TextField, { label: t('after', 'Seconds After'), "data-cy": "sec-after-inpt", type: "number", value: afterTime === undefined ? '' : afterTime.toString(), onChange: (e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '') {
                                setAfterTime(undefined);
                            }
                            else {
                                const newValue = parseInt(inputValue, 10);
                                setAfterTime(newValue >= 0 ? newValue : afterTime);
                            }
                        }, sx: { flex: 1 } })),
                React.createElement(Base, { display: "flex", gap: 4, pt: 4 }, isLoading ? (React.createElement(Base, { display: "flex", justifyContent: "center", width: "100%" },
                    React.createElement(Loading, { "data-cy": "loading-finder-data", text: t('loading', 'Loading') }))) : (React.createElement(Base, { display: "flex", justifyContent: "flex-end", width: "100%", gap: 4 },
                    React.createElement(Button, { type: "button", variant: "text", size: "small", "data-cy": "submit-form-btn", color: "primary", disabled: !isFormValid, onClick: handleLoadDataFromApi }, t('submit', 'Submit')),
                    React.createElement(Button, { type: "button", variant: "contained", size: "small", "data-cy": "download-data-btn", color: "primary", disabled: (finderData === null || finderData === void 0 ? void 0 : finderData.length) === 0 || isLoading || !isFormChanged, onClick: handleDownloadCSVData }, t('download', 'Download')))))))));
};
