import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { repositories } from 'core/repositories';
import { Base, Box, Button, Dialog, List, Page, Typography } from 'lib/cortiUI';
import { AddUpdateUserRoleForm } from './AddUpdateUserRoleForm';
import { UserRoleListItem } from './UserRoleListItem';
export function UserRolesView() {
    const [showAddUpdateRoleModal, setShowAddUpdateRoleModal] = React.useState(false);
    React.useEffect(() => {
        repositories.roles.loadAllRoles();
    }, []);
    const closeModal = () => {
        setShowAddUpdateRoleModal(false);
    };
    const startCreatingUserRole = () => {
        setShowAddUpdateRoleModal(true);
    };
    return (React.createElement(Observer, null, () => {
        const { roles } = repositories.roles;
        const usedRoles = roles.filter((role) => role.isUsed);
        const unusedRoles = roles.filter((role) => !role.isUsed);
        return (React.createElement(Page, { "data-cy": "roles-view" },
            React.createElement(Box, { mb: 8 },
                React.createElement(Button, { variant: "contained", "data-cy": "open-add-update-role-form", className: css({ marginBottom: 2, marginTop: 3, marginRight: 'auto' }), size: "large", color: "primary", onClick: startCreatingUserRole, startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, intl.t('team:userRole.addNewRoleButton', 'Add role'))),
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7 },
                React.createElement(Box, { "data-cy": "active-role-list" },
                    React.createElement(Typography, { variant: "h6", mb: 3, color: "default" }, intl.t('team:userRole.activeRolesTitle', 'Active roles')),
                    React.createElement(List, null, usedRoles.map((userRole) => (React.createElement(UserRoleListItem, { key: userRole.id, userRole: userRole }))))),
                React.createElement(Box, { "data-cy": "unused-role-list" },
                    React.createElement(Typography, { variant: "h6", mb: 3, color: "default" }, intl.t('team:userRole.unusedRolesTitle', 'Unused roles')),
                    React.createElement(List, null, unusedRoles.map((userRole) => (React.createElement(UserRoleListItem, { key: userRole.id, userRole: userRole })))))),
            showAddUpdateRoleModal && (React.createElement(Dialog, { title: intl.t('team:userRole.createUserRole', 'Create new user role'), open: showAddUpdateRoleModal, onClose: closeModal },
                React.createElement(AddUpdateUserRoleForm, { onSubmit: closeModal })))));
    }));
}
