import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addMilliseconds, differenceInMilliseconds, startOfDay } from 'date-fns';
import React from 'react';
import { isDate, isValid } from '@corti/date';
/**
 *
 * @description This is a custom filter input component for filtering by duration. This uses the DesktopTimePicker component from @mui/x-date-pickers directly because it does not need to reflect regarding timezones.
 */
export const DurationFilterInput = ({ item, applyValue }) => {
    const initialTime = item.value != null ? addMilliseconds(startOfDay(new Date()), item.value) : null;
    const [selectedTime, setSelectedTime] = React.useState(initialTime);
    const prevValueRef = React.useRef(item.value);
    function handleApply(time) {
        const totalMilliseconds = time ? differenceInMilliseconds(time, startOfDay(new Date())) : null;
        if (totalMilliseconds !== prevValueRef.current) {
            applyValue(Object.assign(Object.assign({}, item), { value: totalMilliseconds }));
            prevValueRef.current = totalMilliseconds;
        }
    }
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns },
        React.createElement(DesktopTimePicker, { label: "Filter Duration", ampm: false, views: ['hours', 'minutes', 'seconds'], value: selectedTime, onChange: (newTime) => {
                setSelectedTime(newTime);
                if (isDate(newTime) && isValid(newTime)) {
                    handleApply(newTime);
                }
            }, slotProps: {
                textField: {
                    variant: 'standard',
                },
                inputAdornment: {
                    sx: {
                        '& .MuiButtonBase-root': {
                            marginRight: -1,
                        },
                    },
                },
            } })));
};
