import { observer } from 'mobx-react';
import React from 'react';
import { SplitButton } from '@corti/corti-ui';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Autocomplete, Base, Box, Button, Loading, Menu, TextField, Typography } from 'lib/cortiUI';
import { useRealTimeCtx } from '../RealTimeView/RealTimeContext';
import SessionItemCard from './SessionItemCard';
import CopilotIcon from './assets/copilot-logo.png';
import { useFetchSessionsWithCalls, useGraphSelector, useStartSession } from './hooks';
export const HomeView = observer(function HomeView() {
    var _a;
    const { t } = useTranslation('realtimeApp');
    const theme = useTheme();
    const realtime = useRealTimeCtx();
    const { graphOptions, selectedGraphOption, selectedGraphVersionOption, setSelectedGraphOptionID, setSelectedGraphVersionOptionID, } = useGraphSelector();
    const { createNewSession, isNewSessionLoading } = useStartSession({
        selectedGraphVersionOption,
    });
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const sessionOptions = [
        {
            label: t('landingScreen.startNewSessionBtn', 'Start Session'),
            onClick: createNewSession,
        },
    ];
    // This hook is only used to fetch the calls associated with the sessions
    // so we can display the phone number in the session card
    // but for rendering we are still using the cached sessions - to keep offline mode working
    const sortedSessions = useFetchSessionsWithCalls(realtime);
    return (React.createElement(Base, { "data-cy": "realtime-app-view", overflow: "hidden", height: "100%", width: "100%", paddingTop: 67, paddingX: 75, display: "flex", flexDirection: "column", gap: 50 },
        React.createElement(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Base, { display: "flex", gap: 28 },
                React.createElement("img", { src: CopilotIcon, width: 48, height: 48 }),
                React.createElement(Typography, { variant: "h4", fontWeight: 600, color: "default" }, t('landingScreen.welcomeMessage', 'Welcome {{name}}!', {
                    name: authStore.currentUser.name,
                }))),
            React.createElement(SplitButton, { variant: "contained", options: sessionOptions, disabled: !selectedGraphVersionOption, dropdownClickHandler: (e) => setMenuAnchorEl(e.target), groupLabel: t('landingScreen.SessionTypeSelector.groupLabel', 'Session configuration'), dropdownLabel: t('landingScreen.SessionTypeSelector.dropdownLabel', 'Switch session type') }),
            React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl, classes: {
                    list: css({
                        borderRadius: 6,
                    }),
                    paper: css({
                        width: '417px',
                        marginTop: '10px',
                        overflow: 'visible',
                        borderRadius: 6,
                        border: `1px solid ${theme.palette.background.divider}`,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 150,
                            width: 10,
                            height: 10,
                            backgroundColor: 'white',
                            border: `1px solid ${theme.palette.background.divider}`,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    }),
                } },
                React.createElement(Box, { flexDirection: "column", gap: 16, padding: 24 },
                    React.createElement(Autocomplete, { size: "small", options: graphOptions, getOptionLabel: (option) => option.text, value: selectedGraphOption, onChange: (_, option) => {
                            if (option) {
                                setSelectedGraphOptionID(option.value);
                            }
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('landingScreen.graphSelect.label', 'Graph') }))), sx: { minWidth: 250 }, clearIcon: null }),
                    rbacService.hasPermission('app:protocol_graph_editor') && (React.createElement(Autocomplete, { size: "small", options: (_a = selectedGraphOption === null || selectedGraphOption === void 0 ? void 0 : selectedGraphOption.versions) !== null && _a !== void 0 ? _a : [], getOptionLabel: (option) => option.text, value: selectedGraphVersionOption, onChange: (_, option) => {
                            if (option) {
                                setSelectedGraphVersionOptionID(option.value);
                            }
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('landingScreen.graphVersionSelect.label', 'Version') }))), sx: { minWidth: 250 }, clearIcon: null })),
                    React.createElement(Button, { "data-cy": "createNewSessionBtn", color: "primary", disabled: !(selectedGraphVersionOption === null || selectedGraphVersionOption === void 0 ? void 0 : selectedGraphVersionOption.value) || isNewSessionLoading, onClick: createNewSession },
                        React.createElement(Typography, { uppercase: true }, t('landingScreen.startNewSessionBtn', 'Start Session')),
                        isNewSessionLoading && React.createElement(Loading, { ml: 3, size: "small" }))))),
        rbacService.realtimeAppRecentSessionsView() && (React.createElement(Base, { height: "100%", className: css(getScrollerCss({ theme: theme })) }, sortedSessions.size > 0 ? ([...sortedSessions.keys()].map((key) => {
            var _a;
            return (React.createElement(Base, { key: key, mb: 2, mt: 4 },
                React.createElement(Typography, { mb: 2, variant: "subtitle1", fontWeight: 600, color: "neutralGray" }, key === 'Today'
                    ? t('landingScreen.recentSessionsRange.today', 'Today')
                    : key === 'Yesterday'
                        ? t('landingScreen.recentSessionsRange.yesterday', 'Yesterday')
                        : key === '2 days ago'
                            ? t('landingScreen.recentSessionsRange.twoDaysAgo', '2 days ago')
                            : t('landingScreen.recentSessionsRange.older', 'Older')),
                React.createElement(Base, { display: "flex", flexWrap: "wrap", gap: 24, paddingBottom: "2rem", height: "100%" }, (_a = sortedSessions.get(key)) === null || _a === void 0 ? void 0 : _a.map((it) => (React.createElement(SessionItemCard, Object.assign({ key: it.id }, it)))))));
        })) : (React.createElement(Typography, { mb: 2, variant: "subtitle1", fontWeight: 600, color: "neutralGray" }, t('landingScreen.noRecentSessions.label', 'No Recent Sessions')))))));
});
