var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { observer } from 'mobx-react';
import React, { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { Autocomplete, Base, Box, Button, Checkbox, ColorPicker, ColorPickerTrigger, CopyButton, Input, MenuItem, Text, TextField, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
export function useId(identifier) {
    const stableIdentifier = useRef(identifier);
    return useMemo(() => { var _a; return (_a = stableIdentifier.current) !== null && _a !== void 0 ? _a : uuid(); }, []);
}
export function AttributeList(props) {
    return (React.createElement(Base, { my: 5, display: "grid", gridGap: 4, gridAutoRows: "max-content" },
        props.headerTitle && (React.createElement(Text, { noSelect: true, fontWeight: "bold" }, props.headerTitle)),
        props.children));
}
export const AttributeDescriptorRenderer = observer(({ identifier, attributeDescriptor, onValueChange, }) => {
    const properties = { identifier, name: attributeDescriptor.name, onValueChange };
    switch (attributeDescriptor.type) {
        case 'string':
            return (React.createElement(AttributeRendererString, Object.assign({ value: attributeDescriptor.value }, properties, { "data-cy": properties.name.toLowerCase() })));
        case 'number':
            return (React.createElement(AttributeRendererNumber, Object.assign({ value: attributeDescriptor.value, defaultValue: attributeDescriptor.defaultValue }, properties)));
        case 'boolean':
            return React.createElement(AttributeRendererBoolean, Object.assign({ value: attributeDescriptor.value }, properties));
        case 'color':
            return React.createElement(AttributeRendererColor, Object.assign({ value: attributeDescriptor.value }, properties));
        case 'media-asset':
            return (React.createElement(AttributeRendererMediaAsset, Object.assign({ value: attributeDescriptor.value }, properties, { options: attributeDescriptor.options })));
    }
});
export function CustomAttribute(_a) {
    var { name, identifier, children } = _a, rest = __rest(_a, ["name", "identifier", "children"]);
    const theme = useTheme();
    return (React.createElement(Box, Object.assign({ alignItems: "start", width: '100%' }, rest),
        React.createElement("label", { htmlFor: identifier },
            React.createElement(Text, { noSelect: true, fontWeight: "bold", my: 3, color: theme.palette.grey[700] }, name)),
        children));
}
export const AttributeRendererString = forwardRef(function AttributeRendererString(props, ref) {
    const id = useId(props.identifier);
    return (React.createElement(CustomAttribute, { name: props.name, identifier: id },
        React.createElement(Input, { id: id, ref: ref, "data-cy": props['data-cy'], name: props.name, type: "text", value: props.value, disabled: props.disabled, fullWidth: true, onChange: (e) => {
                props.onValueChange(e.target.value);
            } })));
});
export function AttributeRendererNumber({ name, identifier, value, defaultValue, onValueChange, }) {
    return (React.createElement(CustomAttribute, { name: name, identifier: identifier },
        React.createElement(Input, { id: identifier, name: name, type: "number", value: value, fullWidth: true, onChange: (e) => {
                onValueChange(e.target.value === '' ? defaultValue : Number(e.target.value));
            } })));
}
export function AttributeRendererBoolean(props) {
    var _a, _b;
    const id = useId(props.identifier);
    return (React.createElement(CustomAttribute, { name: props.name, identifier: id },
        React.createElement(Checkbox, { "data-cy": props['data-cy'], id: id, name: props.name, checked: (_a = props.value) !== null && _a !== void 0 ? _a : false, disabled: (_b = props.disabled) !== null && _b !== void 0 ? _b : false, onChange: (e) => {
                props.onValueChange(e.target.checked);
            } })));
}
export function AttributeRendererEnum(props) {
    const id = useId(props.identifier);
    const theme = useTheme();
    return (React.createElement(CustomAttribute, { name: props.name, identifier: id },
        React.createElement(Autocomplete, { "data-cy": props['data-cy'], id: id, size: "small", options: props.items, getOptionLabel: (option) => option.text, value: props.items.find((i) => i.value === props.value), onChange: (_, option) => {
                if (option) {
                    props.onValueChange(option.value);
                }
            }, renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: uuid(), "data-cy": "attribute-renderer-menu-item" }),
                React.createElement(Text, { color: theme.palette.grey[700], noWrap: true }, option.text))), renderInput: (params) => React.createElement(TextField, Object.assign({}, params)), clearIcon: null, sx: { minWidth: 220 } })));
}
export function AttributeRendererColor(props) {
    var _a;
    return (React.createElement(CustomAttribute, { name: props.name },
        React.createElement(Base, { display: "flex" },
            React.createElement(ColorPicker, { onColorChange: (value) => {
                    props.onValueChange(value);
                }, colorValue: (_a = props.value) !== null && _a !== void 0 ? _a : '' }, ({ colorValue, getTriggerProps }) => (React.createElement(ColorPickerTrigger, Object.assign({ color: colorValue }, getTriggerProps())))))));
}
export function AttributeRendererMediaAsset(props) {
    var _a;
    const theme = useTheme();
    const { value, onValueChange, options } = props;
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(CustomAttribute, { name: props.name },
        React.createElement(Text, { color: theme.palette.grey[700] }, value ? ((_a = editor.media.provider.resolveMediaAsset(value)) === null || _a === void 0 ? void 0 : _a.name) || '[missing ref]' : '-'),
        React.createElement(Box, { my: 2, flexDirection: "row", gap: 3 },
            React.createElement(Button, { size: "small", onClick: async () => {
                    const m = await editor.media.pickMedia(options);
                    if (m) {
                        onValueChange(m.id);
                    }
                } }, t('attributes.mediaAsset.selectMediaBtn', 'Select media')),
            React.createElement(Button, { size: "small", onClick: () => onValueChange('') }, t('attributes.mediaAsset.clearMediaBtn', 'Clear')))));
}
export const AttributeRendererStatic = (props) => {
    const { name, value } = props;
    const theme = useTheme();
    return (React.createElement(CustomAttribute, { name: name },
        React.createElement(Text, { color: theme.palette.grey[700] }, value || '-')));
};
export const AttributeRendererCopyableString = (props) => {
    const { name, value, getClipboardValue } = props;
    const id = useMemo(uuid, []);
    return (React.createElement(CustomAttribute, { name: name, identifier: id },
        React.createElement(Input, { fullWidth: true, value: value || '-', id: id, endAdornment: React.createElement(CopyButton, { tooltipTitle: "Copied", getValue: getClipboardValue }) })));
};
