import userSessionStorage from '@corti/lib/userSessionStorage';
export function aggregateTimeSeriesDataByPeriod(periodVariant, dataPoints) {
    if (!periodVariant || periodVariant.id === 'daily')
        return dataPoints;
    const newData = [];
    dataPoints.forEach((currentDataPoint, idx) => {
        if (idx === 0 || (idx - 1 !== 0 && idx % periodVariant.value === 0)) {
            newData.push(Object.assign({}, currentDataPoint));
        }
        if (currentDataPoint.startOfPeriod !== newData[newData.length - 1].startOfPeriod) {
            newData[newData.length - 1].callCount += currentDataPoint.callCount;
        }
    });
    return newData;
}
export function getTempTermGroupColor(tempTermGroupsColors, colorsList) {
    const searchTermsCount = tempTermGroupsColors.length;
    const colorsAlreadyInUse = colorsList.map(() => false);
    const isTermGroupsListLonger = searchTermsCount > colorsAlreadyInUse.length;
    for (let i = searchTermsCount - 1; isTermGroupsListLonger ? i > searchTermsCount - colorsAlreadyInUse.length : i > -1; i--) {
        const color = tempTermGroupsColors[i];
        const colorIndex = colorsList.indexOf(color);
        colorsAlreadyInUse[colorIndex] = true;
    }
    const nextColorIndex = colorsAlreadyInUse.indexOf(false);
    return colorsList[nextColorIndex > -1 ? nextColorIndex : 0];
}
export const getChartDataExtremes = (data) => data.reduce((acc, i) => {
    if (i.min < acc.min) {
        acc.min = i.min;
    }
    if (i.max > acc.max) {
        acc.max = i.max;
    }
    return acc;
}, { min: data[0].min, max: data[0].max });
export function visitedCallsCache() {
    const VISITED_CALLS_KEY = 'callsSearch:visitedCalls';
    function getCache() {
        var _a;
        return JSON.parse((_a = userSessionStorage.getItem(VISITED_CALLS_KEY)) !== null && _a !== void 0 ? _a : '{}');
    }
    function saveCache(cache) {
        userSessionStorage.setItem(VISITED_CALLS_KEY, JSON.stringify(cache));
    }
    function getQueryCache(queryID) {
        if (!queryID) {
            console.error('Query ID is not provided to retrieve visited calls');
            return [];
        }
        return getCache()[queryID] || [];
    }
    function cacheVisitedCall(queryID, data) {
        if (!queryID || !data) {
            console.error('Query ID or Call ID is not provided to cache visited call');
            return;
        }
        const cache = getCache();
        const queryCache = cache[queryID] || [];
        const newCallsToCache = Array.isArray(data) ? data : [data];
        saveCache(Object.assign(Object.assign({}, cache), { [queryID]: [...new Set([...queryCache, ...newCallsToCache])] }));
    }
    function clearCache() {
        userSessionStorage.removeItem(VISITED_CALLS_KEY);
    }
    function clearQueryCache(queryID) {
        if (!queryID) {
            console.error('Query ID is not provided to clear query cache');
            return;
        }
        const newCache = Object.assign({}, getCache());
        delete newCache[queryID];
        saveCache(newCache);
    }
    return {
        cacheVisitedCall,
        getQueryCache,
        clearQueryCache,
        clearCache,
    };
}
