import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { repositories } from 'core/repositories';
import { Base, Card, ChipV2, Tooltip, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
import { AgentActions } from '../AgentActions';
export const AcknowledgementRequested = () => {
    var _a;
    const { t } = useTranslation('caseView', {
        keyPrefix: 'acknowledgementStatus.acknowledgementRequested',
    });
    const theme = useTheme();
    const { interaction } = useInteraction();
    const { currentUserID } = useAuth();
    function getReasonByReviewType(reviewType) {
        switch (reviewType) {
            case 'MANUAL':
                return t('manualReview', 'Review request completed by manually marking it as complete.');
            case 'REVIEW_SERVICE':
                return t('reviewService', 'Review request completed by submitting a new assessment.');
            case 'USER_REQUESTED':
                return t('userRequested', 'A team manager requested acknowledgment for this interaction.');
            default:
                return '';
        }
    }
    if (!interaction)
        return null;
    //FIXME: Remove dependency on repositories
    const user = repositories.users.getUser(interaction.acknowledgement_status_updated_by);
    return (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 5, p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(ChipV2, { variant: "outlined", color: "primary", label: t('chipLabel', 'Acknowledgement Requested') }),
            currentUserID === interaction.interaction_owner && (React.createElement(Tooltip, { title: t('tooltipLabel', 'Allows the agent to provide feedback for this interaction. Only available to the interaction owner.') },
                React.createElement(InfoOutlinedIcon, { fontSize: "small" })))),
        React.createElement(Card, { display: "flex", flexDirection: "column", background: currentUserID === interaction.interaction_owner
                ? theme.palette.background.default
                : theme.palette.background.card, style: { boxShadow: 'none', border: 'none' } },
            React.createElement(Typography, { variant: "body2", color: "default" }, getReasonByReviewType(interaction === null || interaction === void 0 ? void 0 : interaction.review_type)),
            React.createElement(Typography, { variant: "body2", color: "neutralGray" },
                t('on', {
                    defaultValue: '{{user}} on ',
                    user: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : 'Unknown User',
                }),
                formatDateTime(new Date(interaction.acknowledgement_status_updated_at)))),
        currentUserID === interaction.interaction_owner && React.createElement(AgentActions, null),
        ' '));
};
