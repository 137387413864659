import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { repositories } from 'core/repositories';
import { Base, Typography } from 'lib/cortiUI';
import { useInteraction } from '../context';
import { FindSimilarInteractions } from './FindSimilarInteractions';
import MetadataLoader from './MetadataLoader';
import { ScreenRecordings } from './ScreenRecordings';
import { CopyableProperty, ExpandableProperties, SingleProperty } from './components';
export const Metadata = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata' });
    const { interaction, isLoading } = useInteraction();
    if (!interaction && isLoading) {
        return React.createElement(MetadataLoader, null);
    }
    if (!interaction)
        return null;
    const interactionOwner = repositories.users.getUser(interaction.interaction_owner);
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "1fr auto", height: "auto", minHeight: 0, gap: 6 },
        React.createElement(Base, { display: "grid", gridTemplateRows: "auto auto 1fr", gap: 6, height: "auto", minHeight: 0 },
            React.createElement(Typography, { variant: "subtitle1", color: "default" }, t('interactionDetails', 'Interaction Details')),
            React.createElement(Base, { display: "grid", gap: 6 },
                interaction.call_started_at && (React.createElement(SingleProperty, { title: t('date', 'Date'), value: formatDateTime(new Date(interaction.call_started_at)) })),
                interactionOwner && (React.createElement(SingleProperty, { title: t('agent', 'Agent'), value: interactionOwner.name })),
                interaction.interaction_type && (React.createElement(SingleProperty, { title: t('interactionType', 'Interaction Type'), value: interaction.interaction_type }))),
            !!interaction.custom_properties.length && (React.createElement(ExpandableProperties, { title: t('customProperties', 'Custom properties'), items: interaction.custom_properties.map((it) => ({
                    title: it.name,
                    value: it.value,
                })) }))),
        React.createElement(Base, { display: "flex" },
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
                React.createElement(ScreenRecordings, null),
                React.createElement(FindSimilarInteractions, null),
                interaction.call_external_id && (React.createElement(CopyableProperty, { title: t('externalId', 'Copy External ID'), value: interaction.call_external_id })),
                React.createElement(CopyableProperty, { title: t('interactionId', ' Copy Interaction ID'), value: interaction.id })))));
};
