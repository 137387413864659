import React from 'react';
import { Base, Typography } from 'lib/cortiUI';
export const LegendItem = ({ value, label, color }) => {
    return (React.createElement(Base, { display: "flex", alignItems: "center", gap: 4 },
        React.createElement(Base, { width: 8, height: 8, borderRadius: "50%", background: color }),
        React.createElement(Base, { display: "flex", alignItems: "center", gap: 3 },
            React.createElement(Typography, { color: "default" },
                value !== Math.round(value) && '~',
                `${Math.round(value)}%`),
            React.createElement(Typography, { color: "neutralGray" }, label))));
};
