import React from 'react';
import { Base, Typography } from 'lib/cortiUI';
export const SummaryList = ({ icon, title, summaryTexts }) => {
    if (!summaryTexts.length)
        return null;
    return (React.createElement(Base, { display: "flex", gap: 2, flexDirection: "column" },
        React.createElement(Typography, { variant: "subtitle2", color: "default" }, title),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 1 }, summaryTexts.map((it) => (React.createElement(Base, { key: it, display: "flex", alignItems: "center", gap: 2 },
            icon,
            React.createElement(Typography, { color: "neutralGray", variant: "body2" }, it)))))));
};
