import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Card, CardContent, ChipV2, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { useDataContext } from '../../../activeView/context/dataContext';
import { useGlobalContext } from '../../../context';
import { getPerformanceStatusColor } from '../../../utils';
import { PerformanceIndicator } from '../../components/PerformanceIndicator';
import { TargetConfigurationTrigger } from '../TargetConfigurationTrigger';
import { GeneralPerformancePanelLoader } from './GeneralPerformancePanelLoader';
export const GeneralPerformancePanel = () => {
    const { palette } = useTheme();
    const { t } = useTranslation('performanceApp', { keyPrefix: 'generalPerformancePanel' });
    const { isEditMode, isDraft } = useGlobalContext();
    const { goal, changeGoal } = useParametersContext();
    const { performanceScore, threshold, isPerformanceViewMetricsLoading } = useDataContext();
    const performanceTarget = goal.enabled ? goal.value : undefined;
    const { status } = getPerformanceStatusColor(palette, performanceScore, threshold, performanceTarget);
    const getChipInfo = () => {
        switch (status) {
            case 'strong':
                return {
                    color: 'success',
                    label: t('generalPerformancePanel.performanceLevel.strong', 'Strong'),
                };
            case 'moderate':
                return {
                    color: 'warning',
                    label: t('generalPerformancePanel.performanceLevel.moderate', 'Moderate'),
                };
            case 'poor':
                return {
                    color: 'error',
                    label: t('generalPerformancePanel.performanceLevel.poor', 'Poor'),
                };
            default:
                return null;
        }
    };
    const chipData = getChipInfo();
    if (isPerformanceViewMetricsLoading) {
        return React.createElement(GeneralPerformancePanelLoader, null);
    }
    return (React.createElement(Card, { height: "100%", pt: 2 },
        React.createElement(CardContent, { sx: { p: 4 } },
            React.createElement(Base, { display: "grid", gap: 2, mb: 10, alignItems: "center" },
                React.createElement(Typography, { variant: "body1", color: "default" }, t('title', 'Compliance')),
                React.createElement(Typography, { color: "neutralGray" }, t('description', 'Average for selected trackers'))),
            React.createElement(Base, { display: "flex", alignItems: "center", mb: 6, justifyContent: "space-between" },
                React.createElement(Base, { display: "flex", alignItems: "center", gap: 4 },
                    React.createElement(Base, { display: "flex" },
                        React.createElement(Typography, { variant: "h4", color: "default" }, performanceScore),
                        React.createElement(Typography, { variant: "h4" }, "%")),
                    chipData && (React.createElement(ChipV2, { label: chipData.label, variant: "filled", sx: { lineHeight: 'initial' }, color: chipData.color, size: "medium" }))),
                (isDraft || isEditMode) && (React.createElement(TargetConfigurationTrigger, { goal: goal, variant: goal.enabled ? 'secondary' : 'primary', onApply: changeGoal }))),
            React.createElement(PerformanceIndicator, { value: performanceScore, target: performanceTarget, threshold: threshold }))));
};
