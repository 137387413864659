import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { trackerService } from 'browser/services/init';
import { Base, CircularProgress, ConfirmableTarget, IconButton, Loading, Menu, MenuItem, SidebarActionButton, SidebarItem, SidebarSection, Typography, UnstyledLink, } from 'lib/cortiUI';
import { visitedCallsCache } from '../utils';
import { useTranscriptSearchViewContext } from './context';
export const TranscriptQueryList = observer(function TranscriptQueryList() {
    const { viewModel, router } = useTranscriptSearchViewContext();
    const { t } = useTranslation('transcriptSearchApp');
    const [deleteQueue, setDeleteQueue] = React.useState([]);
    async function handleQueryDelete(queryID, isActive) {
        try {
            if (isBeingDeleted(queryID))
                return;
            setDeleteQueue((state) => [...state, queryID]);
            await viewModel.deleteQuery(queryID);
            if (isActive && viewModel.state.allQueries) {
                router.toRoute(router.getNextRoute(viewModel.state.allQueries));
            }
            trackerService.track('[Transcript search] transcript query deleted', {
                transcriptQuery: {
                    id: queryID,
                },
            });
        }
        finally {
            const targetIdx = deleteQueue.findIndex((id) => id === queryID);
            if (targetIdx) {
                setDeleteQueue((state) => {
                    const next = [...state];
                    next.splice(targetIdx, 1);
                    return next;
                });
            }
        }
    }
    function isBeingDeleted(queryID) {
        return deleteQueue.includes(queryID);
    }
    if (viewModel.state.isLoadingAllQueries) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", "data-cy": "transcript-query-list-loading" }));
    }
    if (!viewModel.state.allQueries) {
        return null;
    }
    return (React.createElement(SidebarSection, { title: t('transcriptQueries', 'Saved Queries'), actions: React.createElement(UnstyledLink, { to: {
                pathname: router.getRoute('draft'),
                state: { resetDraftQueryParams: true },
            }, onClick: () => {
                visitedCallsCache().clearQueryCache('draft');
            }, render: () => (React.createElement(SidebarActionButton, { icon: React.createElement(AddRoundedIcon, { fontSize: "small" }), tooltipTitle: t('createNewQuery', 'Create New Query'), "data-cy": "transcript-query-list-create-new-search-btn" })) }), "data-cy": "transcript-query-list" }, viewModel.state.allQueries
        .slice()
        .sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, { numeric: true });
    })
        .map((it) => (React.createElement(UnstyledLink, { key: it.id, to: router.getRoute(it.id), disabled: isBeingDeleted(it.id) }, ({ isActive }) => (React.createElement(TranscriptQueryListItem, { query: it, isActive: isActive, onDelete: handleQueryDelete, isDeleteLoading: isBeingDeleted(it.id) })))))));
});
function TranscriptQueryListItem(props) {
    const { query, isActive, onDelete, isDeleteLoading } = props;
    const { t } = useTranslation();
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    function openMenu(anchor) {
        setMenuAnchor(anchor);
    }
    function closeMenu() {
        setMenuAnchor(null);
    }
    function onDeleteConfirm() {
        onDelete(query.id, isActive);
        visitedCallsCache().clearQueryCache(query.id);
        closeMenu();
    }
    function onDeleteCancel(e) {
        e.preventDefault();
    }
    function onActionButtonClick(e) {
        e.preventDefault();
        openMenu(e.currentTarget);
    }
    return (React.createElement(SidebarItem, { selected: isActive },
        React.createElement(Base, { width: "100%", display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", gap: 5, "data-cy": "transcript-query-list-item" },
            React.createElement(Typography, { variant: "body1", noWrap: true }, query.name),
            React.createElement(Base, { minHeight: 34, minWidth: 34, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }, isDeleteLoading ? (React.createElement(CircularProgress, { color: "inherit", size: 20 })) : (React.createElement(IconButton, { size: "small", icon: React.createElement(MoreHorizRoundedIcon, null), onClick: onActionButtonClick, "data-cy": "transcript-query-list-item-action-btn" })))),
        React.createElement(Menu, { open: !!menuAnchor, anchorEl: menuAnchor, onClose: closeMenu },
            React.createElement(ConfirmableTarget, { message: t('transcriptSearchApp:transcriptQueryDeleteConfirmationMsg', 'The transcript query will permanently be removed from your organization. This action cannot be reverted'), onConfirm: onDeleteConfirm, onCancel: onDeleteCancel, color: "error" }, ({ onClick }) => (React.createElement(MenuItem, { onClick: onClick, "data-cy": "transcript-query-delete-btn" },
                React.createElement(Typography, { variant: "body1" }, t('remove', 'Remove'))))))));
}
