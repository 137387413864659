import { repositories } from 'core/repositories';
/**
 *
 * Get grid rows from search results
 */
export function getRows(items) {
    return items.map((item) => {
        var _a, _b, _c, _d;
        return ({
            id: (_a = item.interaction) === null || _a === void 0 ? void 0 : _a.id,
            interactionID: (_b = item.interaction) === null || _b === void 0 ? void 0 : _b.id,
            'call.started_at': item.call ? new Date(item.call.started_at) : null,
            'call.inbound_number': item.call ? item.call.inbound_number : '',
            'call.direction': item.call ? item.call.direction : '',
            'call.source_system': item.call ? item.call.source_system : '',
            'call.duration_millis': item.call ? item.call.duration : 0,
            'call.outbound_number': item.call ? item.call.outbound_number : '',
            'call.participants': item.call && item.call.participants
                ? item.call.participants.map(({ user_id }) => { var _a, _b; return (_b = (_a = repositories.users.getUser(user_id)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ' '; })
                : [],
            'reviews.created_by': Array.from(new Set((_d = (_c = item.reviews) === null || _c === void 0 ? void 0 : _c.map(({ created_by }) => { var _a; return (_a = repositories.users.getUser(created_by)) === null || _a === void 0 ? void 0 : _a.name; }).filter((it) => !!it)) !== null && _d !== void 0 ? _d : [])),
        });
    });
}
