import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { trackerService } from 'browser/services/init';
import { Base, Tooltip } from 'lib/cortiUI';
import { ActionButton } from './ActionButton';
export const CopyableProperty = ({ title, value }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata' });
    const [showTooltip, setShowTooltip] = React.useState(false);
    async function onCopyClick() {
        try {
            await navigator.clipboard.writeText(value);
            trackerService.track('[Case view] copied property', {
                title: title,
                value: value,
            });
            setShowTooltip(true);
        }
        catch (error) {
            // we are not handling the error, but adding an error log to help devs
            console.error(error);
        }
    }
    return (React.createElement(Tooltip, { disableInteractive: true, placement: "top", title: `${title} ${t('copied', 'copied')}`, leaveDelay: 1000, open: showTooltip, onClose: () => setShowTooltip(false) },
        React.createElement(Base, { className: css({ cursor: 'pointer' }) },
            React.createElement(ActionButton, { label: title, icon: React.createElement(ContentCopyRoundedIcon, { color: "action" }), onClick: onCopyClick }))));
};
