import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export const Comparison = ({ difference, label }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.score' });
    function renderScoreComparison(icon) {
        return (React.createElement(Typography, { display: "flex", gap: 1 },
            icon,
            React.createElement(Typography, { variant: "body2", color: "default" }, `${Math.abs(difference)}% `),
            React.createElement(Typography, { variant: "body2", color: "neutralGray" }, `vs. `)));
    }
    function renderPreLabel() {
        switch (true) {
            case difference > 0:
                return renderScoreComparison(React.createElement(ArrowUpwardRoundedIcon, { fontSize: "small", color: "success" }));
            case difference < 0:
                return renderScoreComparison(React.createElement(ArrowDownwardRoundedIcon, { fontSize: "small", color: "error" }));
            default:
                return (React.createElement(Typography, { display: "flex", gap: 1 },
                    React.createElement(RemoveRoundedIcon, { fontSize: "small", color: "action" }),
                    React.createElement(Typography, { variant: "body2", color: "default" }, t('same', 'Same')),
                    React.createElement(Typography, { variant: "body2", color: "neutralGray" }, t('as', 'as'))));
        }
    }
    return (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
        renderPreLabel(),
        React.createElement(Typography, { variant: "body2", color: "neutralGray" }, label)));
};
